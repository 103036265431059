.videos article {
    text-align: center;
    padding-top: 2vh;
}

.videos article iframe {
    height: 50vh;
    width: calc(50vh * 16/9);
    max-width: 80vw;
    max-height: calc(80vw * 9/16);
}