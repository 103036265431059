.accueil {
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
}

.accueil .accueil-img {
    max-height: 60vh;
    max-width: 78vw;
    margin: 0 auto;
}

.accueil .accueil-author {
    display: block;
    margin-top: 5px;
    text-align: right;
}

.accueil-text {
    text-align: justify;
}