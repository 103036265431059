
.parcours {
    display: flex;
    flex-direction: column;
}

.parcours-presentation, .parcours-expositions {
    display: flex;
    margin: 2vh 0;
}

.parcours-presentation .image, .parcours-expositions .image {
    flex-grow: 0;
    flex-shrink: 0;
    width: 35vw;
    background-position: top center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
}

.parcours-presentation .text p {
    text-align: justify;
}

.parcours-expositions .text .title {
    text-transform: uppercase;
    font-size: x-large;
    color: #999;
}
