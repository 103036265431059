body {
    background-color: #1f2a36;
}

.app {
    font-family: Verdana, Arial, Helvetica, Geneva, sans-serif;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-size: calc(10px + 1vmin);
}

@media (min-width: 1280px) {
    html {
        width: calc(1280px + 10vh);
        margin-left: auto;
        margin-right: auto;
    }
}

.app a,
.app a:visited,
.app a:link {
    color: #e2862c;
    text-decoration: none;
    font-weight: bold;
}

.app a:active,
.app a.active,
.app a:hover {
    color: #3c3;
}

.app .header {
    padding: 1vw;
    font-size: calc(10px + 1vmin);
    color: #abcdef;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
}

.app .header .title {
    padding-left: 5vw;
    display: flex;
    margin-top: 2vh;
}

.app .header .title h1 {
    margin-top: 0;
    margin-bottom: 0;
    flex-grow: 1;
}

.app .header .title .button__toggle-menu {
    display: none;
    cursor: pointer;
    text-align: center;
    width: 80px;
    max-width: 80px;
    flex-grow: 0;
}

.app .header .title .button__toggle-menu div {
    margin-left: auto;
    margin-right: auto;
}

.app .menu {
    color: #abcdef;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: calc(10px + 1vmin);
    list-style-type: none;
    text-align: right;
    margin: 0 5vw 0 15vw;
    padding-bottom: 1vh;
    transition: height 400ms ease-in-out;
}

.app div.menu-button {
    width: 35px;
    height: 5px;
    background-color: #FFFFFF;
    margin: 6px 0;
}

.app .menu .menu-item {
    border-bottom: 1px solid;
    padding-left: calc(13px + 1vw);
    padding-bottom: 1vh;
    margin-top: 10px;
}

.app .menu .menu-item:first-child {
    padding-left: 0;
}

@media (max-width: 768px) {
    .app .menu {
        margin: 0 5vw;
        border-bottom: 1px solid;
    }

    .app .menu .menu-item {
        border-bottom-width: 0;
    }

    .app .header .title .button__toggle-menu {
        display: block;
    }

    .app .menu {
        flex-direction: column;
        overflow: hidden;
    }

    .app .menu.menu--closed {
        height: 0;
    }
}

.app .content {
    color: white;
    overflow: auto;
    overflow-x: hidden;
    margin-left: 5vw;
    margin-right: 5vw;
    padding: 1vw;
    flex-grow: 1;
    flex-shrink: 1;
}

.app .footer {
    color: white;
    font-style: italic;
    font-size: calc(6px + 1vmin);
    padding: 5px 5px 5px 11vw;
    flex-grow: 0;
    flex-shrink: 0;
}
