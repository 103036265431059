.contact {
    display: flex;
    /*align-content: center;*/
    flex-direction: column;
}

.contact .contact-img {
    flex-grow: 0;
    flex-shrink: 0;
    display: block;
    margin: 2vh auto 50px auto;
    width: 70%
}

.contact dl {
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}

.contact dd {
    float: left;
}

.contact dt {
    margin-left: 150px;
    margin-bottom: 15px;
}


.contact .contact-address {
    list-style-type: none;
    padding: 0;
}